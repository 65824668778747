import React, { useState } from "react";
import { Popover, Input } from "antd";

const { TextArea } = Input;

export default function VariableTextArea({
  variables,
  text,
  setText,
  placeholder
}) {
  const [showVars, setShowVars] = useState(
    text.charAt(text.length - 1) === "@"
  );

  const onChange = event => {
    const newText = event.target.value;

    if (newText.charAt(newText.length - 1) === "@") {
      setShowVars(true);
    } else if (
      showVars &&
      (newText.charAt(newText.length - 1) === " " ||
        newText.length < text.length)
    ) {
      setShowVars(false);
    }

    setText(event.target.value);
  };

  const variablePopoverContent = (
    <div style={{ color: "#2F7EFF" }}>
      {variables &&
        variables.map(variable => (
          <p
            key={variable.id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setText(`${text}${variable.name}`);
              setShowVars(false);
            }}
          >
            {variable.name}
          </p>
        ))}
    </div>
  );

  return (
    <Popover
      content={variablePopoverContent}
      visible={showVars}
      onVisibleChange={() => setShowVars(false)}
    >
      <TextArea
        autosize
        style={{
          float: "left",
          marginTop: "10px",
          marginBottom: "10px",
          border: "none",
          resize: "none"
        }}
        onChange={onChange}
        {...(!text
          ? {
              placeholder
            }
          : { value: text })}
      />
    </Popover>
  );
}
