import * as firebase from "firebase";
import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);

const databaseRef = firebase.firestore();
const settings = { timestampsInSnapshots: true };
databaseRef.settings(settings);

export const metricsRef = databaseRef.collection("metrics").doc("dev");
export const prodMeticsRef = databaseRef.collection("metrics").doc("prod");
export const metricsConfigRef = databaseRef.collection("metrics").doc("config");
export const db = databaseRef;
