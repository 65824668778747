import { GET_METRICS } from "../actions/types";

export default (state = {}, action) => {
    switch(action.type) {
        case GET_METRICS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}