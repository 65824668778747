/**
 * Start test data
 */
const project = {
  name: "Test project",
  id: "vjobk3itbwc2dov0gc7qvi",
  milestones: {
    asdf: {
      name: "Test Milestone Name"
    }
  }
};

const testItem = {
  name: "Test item",
  status: "In QA",
  qa: "Approved",
  item_id: "asdf",
  id: "n4hcb80927ixy0brcfjmii"
};

const user = {
  name: "Reece"
};

const pathItemTypes = {
  constant: "constant",
  variable: "variable"
};
/**
 * End test data
 */

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, "g"), replacement);
};

const parseVariablePath = (value, input, path) => {
  let output = value;
  path.forEach(item => {
    if (item.type === pathItemTypes.constant) {
      output = output[item.value];
    } else {
      const variablePath = parseVariablePath(
        input[item.value],
        input,
        item.path
      );
      output = output[variablePath] ? output[variablePath] : variablePath;
    }
  });
  return output;
};

const makeMessage = (
  config,
  itemType,
  notificationType,
  item,
  project,
  user
) => {
  const input = {
    itemType,
    notificationType,
    item,
    project,
    user
  };

  const replacer = match => {
    const varName = match.replace("@", "");
    const varConfig = config.variables.filter(
      variable => variable.name === varName
    )[0];

    if (!varConfig) {
      return "";
    }

    const variableText = parseVariablePath(
      input[varConfig.field],
      input,
      varConfig.path
    );

    if (varConfig.link && varConfig.link.enabled) {
      const variableLink = varConfig.link.text.replaceAll(/@\w*/, replacer);
      return `[${variableText}](${variableLink})`;
    } else {
      return `**${variableText}**`;
    }
  };

  const text = config.text ? config.text.replaceAll(/@\w*/, replacer) : "";
  const actionText = config.actionText
    ? config.actionText.replaceAll(/@\w*/, replacer)
    : "";
  const actionLink = config.actionLink
    ? config.actionLink.replaceAll(/@\w*/, replacer)
    : "";

  return {
    text,
    actionText,
    actionLink
  };
};

export default config => {
  return makeMessage(
    config,
    config.itemType,
    config.notificationType,
    testItem,
    project,
    user
  );
};
