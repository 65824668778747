import React, { useState, useEffect } from "react";
import VariablePathItemRow from "./VariablePathItemRow";
import VariableLinkRow from "./VariableLinkRow";
import { Button, Select, Input } from "antd";
import { id } from "../../../../../utils";

import { fieldOptions, pathItemTypes } from "../../../constants";

const { TextArea } = Input;
const { Option } = Select;

export default function NotificationVariableCard({
  variable,
  allVariables,
  updateVariable,
  deleteVariable,
  setChangesToSave
}) {
  const [pathItems, setPathItems] = useState(variable.path);
  const [field, setField] = useState(variable.field);

  const addPathItem = () => {
    pathItems.push({ type: pathItemTypes.constant, value: "", id: id() });
    variable.path = pathItems;
    updateVariable({ ...variable });
    setChangesToSave(true);
    setPathItems(Object.assign([], pathItems));
  };

  const removePathItem = item => {
    const itemIndex = pathItems.indexOf(item);
    pathItems.splice(itemIndex, 1);
    variable.path = pathItems;
    updateVariable({ ...variable });
    setChangesToSave(true);
    setPathItems(Object.assign([], pathItems));
  };

  const updatePathItem = pathItem => {
    const itemIndex = pathItems.map(item => item.id).indexOf(pathItem.id);
    pathItems[itemIndex] = pathItem;
    variable.path = pathItems;
    setChangesToSave(true);
    updateVariable({ ...variable });
    setPathItems(Object.assign([], pathItems));
  };

  useEffect(() => {
    pathItems.forEach(item => {
      if (!item.id) {
        item.id = id();
      }
    });
    setPathItems(Object.assign([], pathItems));
  }, []);

  return (
    <div
      style={{
        padding: "0px 0px 10px",
        marginBottom: "10px",
        fontSize: "16px"
      }}
    >
      <div>
        <div style={{ float: "left" }}>
          <strong>Variable Name:</strong>
          <div
            style={{
              display: "inline-flex",
              marginLeft: "10px"
            }}
          >
            <TextArea
              autosize
              style={{
                marginBottom: "10px",
                border: "none",
                resize: "none",
                fontSize: "16px",
                color: "#2F7EFF"
              }}
              onChange={event => {
                variable.name = event.target.value;
                updateVariable(variable);
              }}
              value={variable.name}
            />
            <span
              style={{
                float: "left",
                marginLeft: "300px",
                width: "50px",
                cursor: "pointer"
              }}
              onClick={() => deleteVariable(variable)}
            >
              Delete
            </span>
          </div>
          <div style={{ fontSize: "14px" }}>
            Type
            <span style={{ marginLeft: "5px", color: "#2F7EFF" }}>
              @{variable.name}
            </span>{" "}
            to use this variable in the notification text
          </div>
        </div>
        <div style={{ clear: "both" }} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <strong>Field: </strong>
        <Select
          style={{ width: "150px" }}
          defaultValue={field}
          onChange={value => {
            setChangesToSave(true);
            setField(value);
            updateVariable({ ...variable, field: value });
          }}
        >
          {Object.values(fieldOptions).map(option => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <div style={{ fontSize: "14px", marginTop: "5px" }}>
          This means this data is sent to the notification processor in the "
          {field}" input
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <strong>Link in Notification Text</strong>
        <div style={{ fontSize: "15px" }}>
          Enable this if you want the variable to be a clickable link when the
          user gets the notification
        </div>
        <VariableLinkRow
          variable={variable}
          allVariables={allVariables}
          updateVariable={updateVariable}
        />
      </div>
      <div>
        <strong>Variable Path on Specified Field:</strong>
        {pathItems.map((pathItem, index) => (
          <VariablePathItemRow
            key={index}
            index={index}
            pathItem={pathItem}
            updatePathItem={updatePathItem}
            remove={removePathItem}
          />
        ))}
        <Button
          style={{ marginTop: "10px", marginLeft: "15px", clear: "both" }}
          onClick={addPathItem}
        >
          Add Path Item
        </Button>
      </div>
    </div>
  );
}
