import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setEnv } from "../../actions/env";
import Header from "../Common/Header";
import { Card, Layout } from "antd";
import "metrics-graphics/dist/metricsgraphics.css";

const { Content } = Layout;

const mapStateToProps = state => ({
  metrics: state.metrics,
  services: state.config.services,
  platforms: state.config.platforms
});

const mapDispatchToProps = {
  setEnv
};

class Dashboard extends Component {
  render() {
    var pages = [
      {
        name: "Feature Toggles",
        description:
          "Control new or experimental features in Aloa Platform based on environment, user, or percentages",
        link: "/features"
      },
      {
        name: "Metrics",
        description: "View Aloa Platform metrics",
        link: "/metrics"
      },
      {
        name: "Email Queue",
        description: "Interact with pending reminder emails for Aloa Pay",
        link: "/emails"
      },
      {
        name: "Notifications",
        description:
          "Configure notification text and format for the Aloa Platform",
        link: "/notifications"
      }
    ];

    return (
      <Content style={{ padding: "0 3%" }}>
        <Header title={"Aloa Platform Control Center"} />
        <h4 style={{ float: "left", marginTop: 20 }}>Pages</h4>
        <div style={{ clear: "both" }}>
          {pages.map(page => (
            <PageCard page={page} key={page.name} />
          ))}
        </div>
      </Content>
    );
  }
}

export class PageCard extends Component {
  render() {
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Link to={this.props.page.link} style={{ textDecoration: "none" }}>
          <Card>
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  fontFamily: "Avenir Next",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#000000",
                  textDecoration: "none",
                  float: "left"
                }}
              >
                {this.props.page.name}
              </div>
              <div
                style={{
                  fontSize: "15px",
                  color: "#aaa",
                  textDecoration: "none",
                  float: "left",
                  clear: "both",
                  marginTop: "10px"
                }}
              >
                {this.props.page.description}
              </div>
              <div style={{ clear: "right" }}> </div>
            </div>
          </Card>
        </Link>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
