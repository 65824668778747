import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../Common/Header";
import { Breadcrumb, Button, Layout } from "antd";
import {
  createNotification,
  getNotifications,
  updateNotification,
  deleteNotification,
  makeNewNotification
} from "../../actions/notifications";
import "metrics-graphics/dist/metricsgraphics.css";

// import makeTestNotifications,  } from "./testData";
import NotificationCard from "./NotificationCard";

const { Content } = Layout;

const mapStateToProps = state => ({
  notifications: state.notifications
});

const mapDispatchToProps = {
  createNotification,
  getNotifications,
  updateNotification,
  deleteNotification
};

function Dashboard({
  notifications,
  createNotification,
  getNotifications,
  updateNotification,
  deleteNotification
}) {
  const [localNotifications, setLocalNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (notifications) {
      setLocalNotifications(Object.assign([], Object.values(notifications)));
    }
  }, [notifications]);

  const addNewNotification = () => {
    const newNotification = makeNewNotification();
    createNotification(newNotification);
    localNotifications.push(makeNewNotification());
    setLocalNotifications(Object.assign([], localNotifications));
  };

  return (
    <Content style={{ padding: "0 3%" }}>
      <Header
        title={"Aloa Platform Notifications Dashboard"}
        hideMetricsHeaderContent
      >
        <Breadcrumb.Item>
          <Link to="/notifications">Notifications</Link>
        </Breadcrumb.Item>
      </Header>
      <div
        style={{
          clear: "both",
          float: "left",
          width: "100%",
          marginTop: "20px"
        }}
      >
        <h4
          style={{
            float: "left",
            fontSize: "20px"
          }}
        >
          Notifications
        </h4>
        <Button onClick={addNewNotification} style={{ float: "right" }}>
          Add New
        </Button>
      </div>
      <div style={{ clear: "both", display: "inline-flex", flexWrap: "wrap" }}>
        {localNotifications
          .sort((a, b) => {
            if (a.itemType > b.itemType) {
              return -1;
            }
            if (b.itemType > a.itemType) {
              return 1;
            }
            return 0;
          })
          .map(item => {
            return (
              <NotificationCard
                key={item.id}
                notification={item}
                createNotification={createNotification}
                updateNotification={updateNotification}
                deleteNotification={deleteNotification}
              />
            );
          })}
      </div>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
