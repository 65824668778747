import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setEnv } from "../../actions/env";
import Header from "../Common/Header";
import { Breadcrumb, Card, Layout } from "antd";
import "metrics-graphics/dist/metricsgraphics.css";

const { Content } = Layout;

const mapStateToProps = state => ({
  metrics: state.metrics,
  services: state.config.services,
  platforms: state.config.platforms
});

const mapDispatchToProps = {
  setEnv
};

class Dashboard extends Component {
  render() {
    var platforms = {};

    if (this.props.config && this.props.config.platforms) {
      platforms = linkPlatformsAndMetrics(
        this.props.config,
        this.props.metrics
      );
    }

    const sortedPlatformList = Object.keys(platforms).sort((a, b) => {
      return platforms[b].total - platforms[a].total;
    });

    return (
      <Content style={{ padding: "0 3%" }}>
        <Header title={"Aloa Platform Metrics Dashboard"}>
          <Breadcrumb.Item>
            <Link to="/metrics">Metrics</Link>
          </Breadcrumb.Item>
        </Header>
        <h4 style={{ float: "left", marginTop: 20 }}>Products</h4>
        <div style={{ clear: "both" }}>
          {this.props.config &&
            this.props.config.platforms &&
            sortedPlatformList.map((item, index) => {
              return <PlatformCard key={index} platform={platforms[item]} />;
            })}
        </div>
      </Content>
    );
  }
}

export class PlatformCard extends Component {
  render() {
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Link
          to={"/metrics/" + this.props.platform.id}
          style={{ textDecoration: "none" }}
        >
          <Card>
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  fontFamily: "Avenir Next",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#000000",
                  textDecoration: "none",
                  float: "left"
                }}
              >
                {this.props.platform.name}
              </div>
              <div style={{ float: "right" }}>
                <div>{this.props.platform.total} Total Events</div>
                <div>{this.props.platform.successes} Successes</div>
                <div>{this.props.platform.errors} Errors</div>
              </div>
              <div style={{ clear: "left", float: "left" }}>
                {this.props.platform.count} Metrics
              </div>
              <div style={{ clear: "right" }}> </div>
            </div>
          </Card>
        </Link>
      </div>
    );
  }
}

const linkPlatformsAndMetrics = (config, metrics) => {
  const platforms = config.platforms;
  Object.keys(platforms).forEach(key => {
    platforms[key].errors = 0;
    platforms[key].successes = 0;
    platforms[key].total = 0;
    platforms[key].count = 0;
    platforms[key].metrics = [];
  });

  if (metrics) {
    Object.keys(metrics).forEach(key => {
      const metric = metrics[key];
      if (platforms[metric.platform]) {
        platforms[metric.platform].metrics.push(metric);
        platforms[metric.platform].count++;
        platforms[metric.platform].errors += metric.errors;
        platforms[metric.platform].successes += metric.successes;
        platforms[metric.platform].total += metric.total;
      }
    });
  }

  return platforms;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
