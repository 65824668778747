import React, { useState } from "react";
import { Select, Input, Button } from "antd";
import { pathItemTypes, fieldOptions } from "../../../constants";
import { id } from "../../../../../utils";

const { Option } = Select;

export default function VariablePathItemRow({
  index,
  pathItem,
  updatePathItem,
  remove
}) {
  const [type, setType] = useState(pathItem.type);
  const [value, setValue] = useState(pathItem.value);
  const [subPaths, setSubPaths] = useState(pathItem.path ? pathItem.path : []);

  const addSubPathItem = () => {
    subPaths.push({ id: id(), type: pathItemTypes.constant, value: "" });
    setSubPaths(Object.assign([], subPaths));
    updatePathItem({ ...pathItem, path: subPaths });
  };

  const removeSubPath = item => {
    const itemIndex = subPaths.indexOf(item);
    subPaths.splice(itemIndex, 1);
    setSubPaths(Object.assign([], subPaths));
    updatePathItem({ ...pathItem, path: subPaths });
  };

  const updateSubPathItem = item => {
    const itemIndex = subPaths.map(item => item.id).indexOf(item.id);
    subPaths[itemIndex] = item;
    setSubPaths(Object.assign([], subPaths));
    updatePathItem({ ...pathItem, path: subPaths });
  };

  return (
    <>
      <div style={{ paddingLeft: "15px", clear: "both" }}>
        <div
          style={{
            float: "left",
            width: "10px",
            marginRight: "15px",
            paddingTop: "15px"
          }}
        >
          {index}
        </div>
        <div style={{ float: "left", width: "150px" }}>
          <div>
            <strong>Type</strong>
          </div>
          <Select
            defaultValue={type}
            onChange={newType => {
              setType(newType);
              updatePathItem({ ...pathItem, type: newType });
            }}
          >
            <Option value="constant">Constant</Option>
            <Option value="variable">Variable</Option>
          </Select>
        </div>
        <div style={{ float: "left", width: "150px" }}>
          <div>
            <strong>{type === "variable" ? "Input Field" : "Value"}</strong>
          </div>
          {type === "constant" && (
            <Input
              style={{
                color: "#E7AF6F"
              }}
              value={value}
              onChange={e => {
                setValue(e.target.value);
                updatePathItem({ ...pathItem, value: e.target.value });
              }}
            />
          )}
          {type === "variable" && (
            <>
              <Select
                style={{ color: "#2F7EFF", width: "150px" }}
                defaultValue={value}
                onChange={newValue => {
                  setValue(newValue);
                  updatePathItem({ ...pathItem, value: newValue });
                }}
              >
                {Object.values(fieldOptions).map(option => (
                  <Option
                    key={option}
                    style={{ color: "#2F7EFF" }}
                    value={option}
                  >
                    {option}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </div>
        <div
          style={{
            cursor: "pointer",
            float: "left",
            marginTop: "28px",
            marginLeft: "20px"
          }}
          onClick={() => remove(pathItem)}
        >
          Delete
        </div>
        {type === "variable" && (
          <div style={{ marginLeft: "15px", clear: "both" }}>
            <strong
              style={{
                marginLeft: "15px",
                marginTop: "5px",
                float: "left",
                clear: "both"
              }}
            >
              Path
            </strong>
            {subPaths.map((subPath, index) => (
              <VariablePathItemRow
                key={subPath.id}
                index={index}
                pathItem={subPath}
                remove={removeSubPath}
                updatePathItem={updateSubPathItem}
              />
            ))}
            <Button
              style={{ marginTop: "10px", marginLeft: "15px", clear: "both" }}
              onClick={addSubPathItem}
            >
              Add Path Item
            </Button>
          </div>
        )}
      </div>
      <div style={{ clear: "both" }} />
    </>
  );
}
