import React, { useState } from "react";
import {
  Popconfirm,
  Card,
  Slider,
  Button,
  Switch,
  Icon,
  Input,
  message,
  Modal
} from "antd";
import "metrics-graphics/dist/metricsgraphics.css";

const { TextArea } = Input;

export default function FeatureCard({
  feature,
  createFeature,
  updateFeature,
  deleteFeature
}) {
  const [devPercent, setDevPercent] = useState(
    feature.environments.dev.percent
  );
  const [prodPercent, setProdPercent] = useState(
    feature.environments.prod.percent
  );
  const [title, setTitle] = useState(feature.title);
  const [changesToSave, setChangesToSave] = useState(false);
  const [description, setDescription] = useState(feature.description);
  const [users, setUsers] = useState(feature.users);
  const [collapsed, setCollapsed] = useState(true);
  const [showAddUser, setShowAddUser] = useState(false);
  const [userToAdd, setUserToAdd] = useState("");

  const updateUser = user => {
    const update = {};
    update[user.id] = user;
    setChangesToSave(true);
    setUsers({ ...users, ...update });
  };

  const updateDescription = (event, type) => {
    const update = {};
    update[type] = event.target.value;
    setChangesToSave(true);
    setDescription({ ...description, ...update });
  };

  const addUser = userId => {
    const add = {};
    add[userId] = {
      name: `${userId}`,
      dev: false,
      prod: false
    };
    setUsers({ ...users, ...add });
    setChangesToSave(true);
  };

  const deleteUser = user => {
    delete users[user.id];
    setChangesToSave(true);
    setUsers({ ...users });
  };

  const saveChanges = () => {
    setChangesToSave(false);
    const updatedFeature = {
      ...feature,
      title,
      description,
      environments: {
        dev: {
          percent: devPercent
        },
        prod: {
          percent: prodPercent
        }
      },
      users
    };

    if (updatedFeature.new) {
      if (updatedFeature.title === "New Feature") {
        message.error("A title is required for a new feature");
      } else {
        delete updatedFeature.new;
        updatedFeature.id = updatedFeature.title
          .toUpperCase()
          .split(" ")
          .join("_");
        createFeature(updatedFeature)
          .then(result => message.success(result.message))
          .catch(error => message.error(error.message));
      }
    } else {
      if (updatedFeature.title !== feature.title) {
        console.log("Getting here");
        updatedFeature.id = updatedFeature.title
          .toUpperCase()
          .split(" ")
          .join("_");
        deleteFeature(feature);
        createFeature(updatedFeature)
          .then(result => message.success(result.message))
          .catch(error => message.error(error.message));
      } else {
        updateFeature(updatedFeature);
      }
    }
  };

  const archiveFeature = () => {
    deleteFeature(feature);
  };

  return (
    <div
      style={{
        clear: "both",
        float: "left",
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px"
      }}
    >
      <Card
        style={{
          cursor: collapsed ? "pointer" : ""
        }}
        onClick={() => {
          if (collapsed) setCollapsed(false);
        }}
      >
        <div style={{ float: "right" }}>
          <Button
            style={{ marginRight: "15px", marginBottom: "5px" }}
            hidden={collapsed}
            type={changesToSave ? "primary" : ""}
            onClick={saveChanges}
          >
            Save Changes
          </Button>
          <Icon
            style={{ cursor: "pointer" }}
            type={collapsed ? "down" : "up"}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div style={{ textAlign: "left" }}>
          <TextArea
            autosize
            style={{
              fontFamily: "Avenir Next",
              fontSize: "30px",
              fontWeight: 500,
              color: "#000000",
              textDecoration: "none",
              float: "left",
              cursor: "pointer",
              height: "40px",
              width: "60%",
              maxWidth: "75%",
              minWidth: "300px",
              border: "none",
              marginBottom: "10px",
              marginLeft: "-15px",
              resize: "none"
            }}
            onChange={event => {
              setTitle(event.target.value);
              setChangesToSave(true);
            }}
            {...(feature.new ? { placeholder: title } : { value: title })}
          />
          <TextArea
            autosize
            style={{
              clear: "left",
              float: "left",
              marginBottom: "10px",
              border: "none",
              resize: "none"
            }}
            onChange={event => updateDescription(event, "general")}
            {...(feature.new
              ? { placeholder: description.general }
              : { value: description.general })}
          />
          <div hidden={collapsed}>
            <div
              style={{
                marginBottom: "20px",
                float: "left",
                clear: "both",
                width: "100%"
              }}
            >
              <div
                style={{
                  clear: "left",
                  float: "left",
                  width: "50%",
                  paddingRight: "10px"
                }}
              >
                <div
                  style={{
                    fontFamily: "Avenir Next",
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#000000",
                    textDecoration: "none"
                  }}
                >
                  On Behavior
                </div>
                <TextArea
                  autosize
                  style={{ border: "none", resize: "none" }}
                  {...(feature.new
                    ? { placeholder: description.on }
                    : { value: description.on })}
                  onChange={event => updateDescription(event, "on")}
                />
              </div>
              <div
                style={{
                  clear: "right",
                  float: "left",
                  width: "50%"
                }}
              >
                <div
                  style={{
                    fontFamily: "Avenir Next",
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#000000",
                    textDecoration: "none"
                  }}
                >
                  Off Behavior
                </div>
                <TextArea
                  autosize
                  style={{ border: "none", resize: "none" }}
                  {...(feature.new
                    ? { placeholder: description.off }
                    : { value: description.off })}
                  onChange={event => updateDescription(event, "off")}
                />
              </div>
            </div>
            <div
              style={{
                fontFamily: "Avenir Next",
                fontSize: "20px",
                fontWeight: 500,
                color: "#000000",
                textDecoration: "none",
                clear: "both",
                marginTop: "40px"
              }}
            >
              Environments
            </div>
            <div
              style={{
                clear: "right",
                float: "left",
                width: "50%",
                fontSize: "18px",
                minWidth: "303px"
              }}
            >
              {`Dev: ${devPercent}%`}
              <Slider
                style={{ marginLeft: "5%", marginRight: "5%" }}
                value={devPercent}
                onChange={value => {
                  setDevPercent(value);
                  setChangesToSave(true);
                }}
              />
            </div>
            <div
              style={{
                clear: "right",
                float: "right",
                width: "50%",
                fontSize: "18px",
                marginBottom: "20px",
                minWidth: "303px"
              }}
            >
              {`Prod: ${prodPercent}%`}
              <Slider
                style={{ marginLeft: "5%", marginRight: "5%" }}
                value={prodPercent}
                onChange={value => {
                  setProdPercent(value);
                  setChangesToSave(true);
                }}
              />
            </div>
            <div style={{ clear: "both" }}>
              <div
                style={{
                  fontFamily: "Avenir Next",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#000000",
                  textDecoration: "none",
                  float: "left",
                  marginBottom: "10px"
                }}
              >
                Whitelisted Users
                <Button
                  shape="circle"
                  size="small"
                  type={"primary"}
                  icon="plus"
                  onClick={() => setShowAddUser(true)}
                  style={{ marginLeft: "10px", paddingTop: "2px" }}
                />
                <Modal
                  title="Whitelisted User ID"
                  visible={showAddUser}
                  onOk={() => {
                    addUser(userToAdd);
                    setShowAddUser(false);
                  }}
                  onCancel={() => setShowAddUser(false)}
                >
                  <Input
                    onChange={event => setUserToAdd(event.target.value)}
                    {...(userToAdd === ""
                      ? { placeholder: "User ID" }
                      : { value: userToAdd })}
                  />
                </Modal>
              </div>
            </div>
            <div style={{ clear: "both" }}>
              {Object.keys(users).map(userId => (
                <UserCard
                  id={userId}
                  user={users[userId]}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                />
              ))}
            </div>
            <div
              style={{
                marginTop: "20px",
                float: "right"
              }}
            >
              <Popconfirm
                title="Are you sure you want to archive this feature?"
                onConfirm={archiveFeature}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ backgroundColor: "#fff", color: "#f5222d" }}
                  type="danger"
                >
                  Delete Feature
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

function UserCard({ id, user, updateUser, deleteUser }) {
  const updateDevState = checked => {
    user.dev = checked;
    updateUser({ id, ...user });
  };

  const updateProdState = checked => {
    user.prod = checked;
    updateUser({ id, ...user });
  };

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          borderTop: "1px solid #eee",
          paddingTop: "10px"
        }}
      >
        <div
          style={{
            textDecoration: "none",
            clear: "both",
            float: "left"
          }}
        >
          <div style={{ fontSize: "18px", fontWeight: "200", color: "#444" }}>
            {`${user.name}`}
          </div>
          <div
            style={{ clear: "left", color: "#aaa", marginLeft: "5px" }}
          >{`${id}`}</div>
        </div>

        <div
          style={{
            fontSize: "16px",
            float: "left",
            paddingTop: "12px",
            marginLeft: "5%"
          }}
        >
          <span style={{ marginRight: "10px" }}>Dev:</span>
          <Switch
            size="small"
            checked={user.dev}
            onClick={updateDevState}
            style={{ marginRight: "10px" }}
          />
          <span style={{ marginRight: "10px" }}>Prod:</span>
          <Switch size="small" checked={user.prod} onClick={updateProdState} />
        </div>
        <div style={{ float: "right" }}>
          <Icon
            style={{ cursor: "pointer", marginTop: "18px" }}
            onClick={() => {
              deleteUser({ id, ...user });
            }}
            type="close"
          />
        </div>
      </div>
      <div style={{ clear: "left", color: "#aaa", marginLeft: "5px" }} />
    </div>
  );
}
