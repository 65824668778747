import { db } from "../config/firebase";
import {
  notificationType,
  pathItemTypes,
  fieldOptions
} from "../components/Notifications/constants";
import { id } from "../utils";

export const actionTypes = {
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  GET_NOTIFICATION: "GET_NOTIFICATION",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION"
};

export const createNotification = newNotification => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (
      getState().notifications &&
      getState().notifications[newNotification.id]
    ) {
      reject(Error("Notification with that ID already exists"));
    }

    db.collection("notifications")
      .doc(newNotification.id)
      .set(newNotification)
      .then(() => {
        resolve({
          message: "Notification successfully saved",
          newNotification
        });
        dispatch({
          type: actionTypes.CREATE_NOTIFICATION,
          data: newNotification
        });
      })
      .catch(error => reject(error));
  });

export const getNotification = id => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("notifications")
      .doc(id)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
          dispatch({
            type: actionTypes.GET_NOTIFICATION,
            data: doc.data()
          });
        } else {
          reject(Error("Notification with that ID does not exist"));
        }
      })
      .catch(error => reject(error));
  });

export const getNotifications = () => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("notifications")
      .get()
      .then(snapshot => {
        const data = [];
        snapshot.forEach(doc => {
          data.push(doc.data());
        });
        resolve(data);
        dispatch({
          type: actionTypes.GET_NOTIFICATIONS,
          data
        });
      })
      .catch(error => reject(error));
  });

export const updateNotification = notification => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("notifications")
      .doc(notification.id)
      .update(notification)
      .then(() => {
        resolve(notification);
        dispatch({
          type: actionTypes.UPDATE_NOTIFICATION,
          data: notification
        });
      })
      .catch(error => reject(error));
  });

export const deleteNotification = notification => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("notifications")
      .doc(notification.id)
      .delete()
      .then(() => {
        resolve({ message: "Notification deleted" });
        dispatch({
          type: actionTypes.DELETE_NOTIFICATION,
          data: notification
        });
      })
      .catch(error => reject(error));
  });

export const makeNewNotification = () => ({
  id: id(),
  title: "New Notification",
  description: "",
  itemType: "",
  notificationType: notificationType.new,
  text: "",
  variables: [
    {
      id: id(),
      name: "itemId",
      field: fieldOptions.item,
      path: [
        {
          type: pathItemTypes.constant,
          value: "id"
        }
      ],
      link: {
        enabled: false,
        text: ""
      }
    },
    {
      id: id(),
      name: "itemType",
      field: fieldOptions.itemType,
      path: [],
      link: {
        enabled: false,
        text: ""
      }
    },
    {
      id: id(),
      name: "projectId",
      field: fieldOptions.project,
      path: [
        {
          type: pathItemTypes.constant,
          value: "id"
        }
      ],
      link: {
        enabled: false,
        text: ""
      }
    }
  ]
});
