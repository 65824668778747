import {
  metricsConfigRef,
  metricsRef,
  prodMeticsRef
} from "../config/firebase";
import { GET_METRICS, GET_CONFIG } from "./types";
import moment from "moment";

var listeners = [];

export const getMetrics = (env, timeSpan, next) => async dispatch => {
  metricsConfigRef.get().then(doc => {
    if (doc.exists) {
      dispatch({
        type: GET_CONFIG,
        data: doc.data()
      });

      const config = doc.data();

      fetchMetrics(env, config.types, timeSpan, dispatch);

      if (next) next();
    } else {
      console.error("Error getting metrics");
    }
  });
};

const fetchMetrics = (env, config, timeSpan, dispatch) => {
  var ref = metricsRef;

  if (env === "prod") {
    ref = prodMeticsRef;
  }

  if (listeners) {
    console.log("Unsubscribing env listeners");
    listeners.forEach(listener => {
      listener();
    });
  }

  const timeCutoff = moment().subtract(1, timeSpan);

  Object.keys(config).forEach(id => {
    const metric = config[id];
    if (metric.platform && metric.service && metric.name) {
      const metricKey = `${metric.platform}-${metric.service}-${metric.name}`;
      var metricRef = ref
        .collection(metric.platform)
        .doc(metric.service)
        .collection(metric.name);
      metricRef = timeCutoff
        ? metricRef.where("date", ">=", timeCutoff.toDate())
        : metricRef;
      const listener = metricRef.onSnapshot(
        snapshot => {
          var metricEvents = [];
          var successes = 0;
          var errors = 0;
          var total = 0;

          snapshot.forEach(doc => {
            const event = doc.data();
            metricEvents.push(event);
            total++;
            if (event.status === "success") {
              successes++;
            } else if (event.status === "failure") {
              errors++;
            }
          });

          const data = {};
          data[metricKey] = {
            platform: metric.platform,
            service: metric.service,
            name: metric.name,
            events: metricEvents,
            successes: successes,
            errors: errors,
            total: total
          };

          dispatch({
            type: GET_METRICS,
            data: data
          });
        },
        err => {
          console.error("Encounter error: ", err);
        }
      );
      listeners.push(listener);
    } else {
      console.log("Error with metric format", metric);
    }
  });
};
