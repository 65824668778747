import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

export default function TypeSelectorRow({
  itemType,
  setItemType,
  notificationType,
  setNotificationType,
  field,
  setField
}) {
  return (
    <div>
      <div style={{ float: "left", width: "100%", fontSize: "18px" }}>
        Notification Type Configuration
        <div style={{ fontSize: "14px" }}>
          <span>Event Type</span>
          <span style={{ marginLeft: "210px" }}>Item Type</span>
          <span style={{ marginLeft: "210px" }}>Field</span>
        </div>
      </div>
      <TextArea
        autosize
        style={{
          float: "left",
          marginBottom: "10px",
          border: "none",
          resize: "none",
          width: "250px",
          marginRight: "1%",
          color: "#2F7EFF"
        }}
        onChange={event => setNotificationType(event.target.value)}
        {...(!notificationType
          ? {
              placeholder: "update or new"
            }
          : { value: notificationType })}
      />
      <TextArea
        autosize
        style={{
          float: "left",
          marginLeft: "10px",
          marginBottom: "10px",
          border: "none",
          resize: "none",
          width: "250px",
          color: "#2F7EFF"
        }}
        onChange={event => setItemType(event.target.value)}
        {...(!itemType
          ? {
              placeholder: "status, feedback, standup, etc."
            }
          : { value: itemType })}
      />
      <TextArea
        autosize
        style={{
          float: "left",
          marginLeft: "10px",
          marginBottom: "10px",
          border: "none",
          resize: "none",
          width: "250px",
          color: "#2F7EFF"
        }}
        onChange={event => setField(event.target.value)}
        {...(!field
          ? {
              placeholder:
                "status, name, date, etc. (can leave blank if new item)"
            }
          : { value: field })}
      />
    </div>
  );
}
