import React from "react";
import NotificationVariableCard from "./NotificationVariableCard";
import { Button } from "antd";

export default function NotificationVariableRow({
  variables,
  addVariable,
  updateVariable,
  deleteVariable,
  setChangesToSave
}) {
  return (
    <div style={{ float: "left", width: "100%" }}>
      <div style={{ fontSize: "18px" }}>Notification Text Variables</div>
      <div>
        {variables.map(variable => (
          <NotificationVariableCard
            key={variable.id}
            variable={variable}
            allVariables={variables}
            updateVariable={updateVariable}
            deleteVariable={deleteVariable}
            setChangesToSave={setChangesToSave}
          />
        ))}
      </div>
      <Button style={{ marginTop: "25px" }} onClick={addVariable}>
        Add New Variable
      </Button>
    </div>
  );
}
