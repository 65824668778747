import React, { Component } from 'react';
import MetricsGraphics from 'react-metrics-graphics';
import 'metrics-graphics/dist/metricsgraphics.css';
import moment from 'moment';
import * as d3 from 'd3';

class Graph extends Component {
  render() {
    var successMetrics = group(this.props.metric.events, "success", this.props.grouping);
    successMetrics = successMetrics ? successMetrics : []; 

    var failedMetrics = group(this.props.metric.events, "failure", this.props.grouping);
    failedMetrics = failedMetrics ? failedMetrics : []; 

    const curveLinear = d3.curveLinear;
    
    if((successMetrics.length > 0 || failedMetrics.length > 0) && this.props.metric) {
        return (
            <MetricsGraphics
                // title={formatTitle(this.props.metric.name)}
                data={[successMetrics, failedMetrics]}
                animate_on_load={true}
                legend= {['Success', 'Failure']}
                interpolate={curveLinear}
                width={this.props.width}
                height={this.props.height}
                // width={100}
                // height={50}
                missing_is_zero={true}
                right={60}
                x_accessor="date"
                y_accessor="value"
                custom_line_color_map={[1,3]}
                point_size={3}
                active_point_on_lines={true}
                active_point_accessor={'active'}
                active_point_size={2}
            />
        );
    } else {
        return (
            <MetricsGraphics
                // title={formatTitle(this.props.metric.name)}
                chart_type={'missing-data'}
                width={this.props.width}
                height={this.props.height}
            />
        )
    }
  }
}

const group = (metrics, result, grouping) => {
    const graph = {};
    for(var item in metrics) {
        const metric = metrics[item];
        if(metric.status === result) {
            let key;
            
            if(grouping !== '10minutes') {
                key = moment(metric.date.toDate()).startOf(grouping);
            } else {
                key = moment(metric.date.toDate());
                key.minutes(Math.round(moment(metric.date.toDate()).minutes() / 10) * 10);
            }
            console.log(key);
            if(graph[key]) {
                graph[key]++;
            } else {
                graph[key] = 1;
            }
        }
    }

    var data = []
    Object.keys(graph).forEach(key => {
        data.push({date: moment(key).toDate(), value: graph[key]});
    });

    console.log(data);
    return data;
}

export default Graph;
