export const fieldOptions = {
  itemType: "itemType",
  field: "field",
  notificationType: "notificationType",
  item: "item",
  project: "project",
  user: "user"
};

export const pathItemTypes = {
  constant: "constant",
  variable: "variable"
};

export const notificationType = {
  new: "new",
  update: "update"
};
