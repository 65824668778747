import React from "react";
import { Checkbox } from "antd";
import VariableTextArea from "../../VariableTextArea";

export default function VariableLinkRow({
  variable,
  updateVariable,
  allVariables
}) {
  const linkEnabled = variable.link && variable.link.enabled;
  const text = variable.link ? variable.link.text : "";

  const setLinkEnabled = checked => {
    variable.link.enabled = checked;
    updateVariable({ ...variable });
  };

  const setText = newText => {
    variable.link.text = newText;
    updateVariable(variable);
  };

  return (
    <div>
      <Checkbox
        checked={linkEnabled}
        onChange={e => setLinkEnabled(e.target.checked)}
      >
        Link in Notification
      </Checkbox>
      {linkEnabled && (
        <VariableTextArea
          setText={setText}
          text={text}
          placeholder={
            "Endpoint to link using other variables (ex: /p/@projectId/status?status=@itemId)"
          }
          variables={allVariables}
        />
      )}
    </div>
  );
}
