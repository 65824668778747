import React, { useState } from "react";
import { Popconfirm, Card, Button, Icon, Input, message } from "antd";
import TypeSelectorRow from "./TypeSelectorRow";
import NotificationTextRow from "./NotificationTextRow";
import NotificationVariableRow from "./NotificationVariableRow";
import VariableTextArea from "./VariableTextArea";
import { id } from "../../../utils";
import { fieldOptions } from "../constants";
import testConfig from "./Test";
import parseMessage from "./MessageParser";

const { TextArea } = Input;

export default function NotificationCard({
  notification,
  updateNotification,
  deleteNotification,
  createNotification
}) {
  const [changesToSave, setChangesToSave] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const [title, setTitle] = useState(notification.title);
  const [description, setDescription] = useState(notification.description);
  const [itemType, setItemType] = useState(notification.itemType);
  const [notificationType, setNotificationType] = useState(
    notification.notificationType
  );
  const [field, setField] = useState(
    notification.field ? notification.field : ""
  );
  const [variables, setVariables] = useState(notification.variables);
  const [text, setText] = useState(notification.text ? notification.text : "");
  const [actionText, setActionText] = useState(
    notification.actionText ? notification.actionText : ""
  );
  const [actionLink, setActionLink] = useState(
    notification.actionLink ? notification.actionLink : ""
  );

  const saveChanges = () => {
    setChangesToSave(false);
    updateNotification(getUpdatedNotification());
  };

  const archiveNotification = () => {
    deleteNotification(notification);
  };

  const getUpdatedNotification = () => ({
    ...notification,
    title,
    text,
    field,
    actionText,
    actionLink,
    description,
    itemType,
    notificationType,
    variables
  });

  const nextChar = () => {
    const takenChars = {};
    return String.fromCharCode(
      variables
        .filter(variable => variable.name.length === 1)
        .reduce((nextAvailable, variable) => {
          const currentChar = variable.name.charCodeAt(0);

          if (currentChar === nextAvailable) {
            do {
              nextAvailable += 1;
            } while (takenChars[nextAvailable]);

            takenChars[nextAvailable] = true;
            return nextAvailable;
          } else {
            if (takenChars[currentChar]) {
              return nextAvailable + 1;
            } else {
              takenChars[currentChar] = true;
              return nextAvailable;
            }
          }
        }, "a".charCodeAt())
    );
  };

  const addVariable = () => {
    variables.push({
      id: id(),
      name: `${nextChar()}`,
      field: fieldOptions.item,
      path: [],
      link: {
        enabled: false,
        text: ""
      }
    });
    setChangesToSave(true);
    setVariables(Object.assign([], variables));
  };

  const updateVariable = item => {
    const index = variables.findIndex(variable => item.id === variable.id);
    variables[index] = item;
    setChangesToSave(true);
    setVariables(Object.assign([], variables));
  };

  const deleteVariable = item => {
    const itemIndex = variables.indexOf(item);
    variables.splice(itemIndex, 1);
    setChangesToSave(true);
    setVariables(Object.assign([], variables));
  };

  return (
    <div
      style={{
        clear: "both",
        float: "left",
        minWidth: "350px",
        marginRight: collapsed ? "20px" : "0",
        width: collapsed ? "23%" : "100%"
      }}
    >
      <Card
        style={{
          cursor: collapsed ? "pointer" : "",
          marginBottom: "20px"
        }}
        onClick={() => {
          if (collapsed) setCollapsed(false);
        }}
      >
        <div style={{ float: "right" }}>
          <Button
            style={{ marginRight: "15px", marginBottom: "5px" }}
            hidden={collapsed}
            onClick={() => createNotification(getUpdatedNotification())}
          >
            Duplicate
          </Button>
          <Button
            style={{ marginRight: "15px", marginBottom: "5px" }}
            hidden={collapsed}
            type={changesToSave ? "primary" : ""}
            onClick={saveChanges}
          >
            Save Changes
          </Button>
          <Button
            style={{ marginRight: "15px", marginBottom: "5px" }}
            hidden={collapsed}
            onClick={() => {
              try {
                const testMessage = testConfig(getUpdatedNotification());
                message.info(parseMessage(testMessage));
              } catch (error) {
                console.error(error);
                message.error("Error creating notification text");
              }
            }}
          >
            Test
          </Button>
          <Icon
            style={{ cursor: "pointer" }}
            type={collapsed ? "down" : "up"}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div style={{ textAlign: "left" }}>
          <TextArea
            autosize
            style={{
              fontFamily: "Avenir Next",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000000",
              textDecoration: "none",
              float: "left",
              cursor: "pointer",
              height: "40px",
              width: "60%",
              maxWidth: "75%",
              minWidth: "300px",
              border: "none",
              marginBottom: "10px",
              marginLeft: "-15px",
              resize: "none"
            }}
            onChange={event => {
              setTitle(event.target.value);
              setChangesToSave(true);
            }}
            {...(notification.new ? { placeholder: title } : { value: title })}
          />
          <TextArea
            autosize
            style={{
              clear: "left",
              float: "left",
              marginBottom: "10px",
              border: "none",
              resize: "none"
            }}
            onChange={event => {
              setChangesToSave(true);
              setDescription(event.target.value);
            }}
            {...(description
              ? { value: description }
              : { placeholder: "Optional notification description" })}
          />
          <div hidden={collapsed}>
            <TypeSelectorRow
              itemType={itemType}
              notificationType={notificationType}
              field={field}
              setItemType={type => {
                setChangesToSave(true);
                setItemType(type);
              }}
              setNotificationType={type => {
                setChangesToSave(true);
                setNotificationType(type);
              }}
              setField={field => {
                setChangesToSave(true);
                setField(field);
              }}
            />
            <NotificationTextRow
              title={"Notification Text Format"}
              placeholder={"Notification Text (ex: New status update in %a)"}
              text={text}
              setText={value => {
                setChangesToSave(true);
                setText(value);
              }}
              variables={variables}
              addVariable={addVariable}
            />
            <NotificationTextRow
              title={"Notification Button Format"}
              placeholder={"Button Text (ex: View new item)"}
              text={actionText}
              setText={value => {
                setChangesToSave(true);
                setActionText(value);
              }}
              variables={variables}
              addVariable={addVariable}
            />
            <div style={{ float: "left", fontSize: "17px" }}>Button Link</div>
            <VariableTextArea
              setText={value => {
                setChangesToSave(true);
                setActionLink(value);
              }}
              placeholder={"Button Link /p/@projectId/status?status=@itemId"}
              text={actionLink}
              variables={variables}
            />
            <NotificationVariableRow
              variables={variables}
              setVariables={setVariables}
              addVariable={addVariable}
              deleteVariable={deleteVariable}
              updateVariable={updateVariable}
              setChangesToSave={setChangesToSave}
            />
            <div
              style={{
                float: "right"
              }}
            >
              <Popconfirm
                title="Are you sure you want to archive this notification?"
                onConfirm={archiveNotification}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ backgroundColor: "#fff", color: "#f5222d" }}
                  type="danger"
                >
                  Delete Notification
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
