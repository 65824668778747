import { combineReducers } from "redux";
import metrics from "./metrics";
import config from "./config";
import env from "./env";
import timespan from "./timespan";
import grouping from "./grouping";
import features from "./features";
import notifications from "./notifications";

export default combineReducers({
  metrics,
  config,
  env,
  timespan,
  grouping,
  features,
  notifications
});
