import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import MetricsDash from "./components/MetricsDash";
import ServicePage from "./components/ServicePage";
import PlatformPage from "./components/PlatformPage";
import { getMetrics } from "./actions";
import "./App.css";
import FeaturesDash from "./components/Features/Dash";
import Notifications from "./components/Notifications";

const mapStateToProps = state => ({
  config: state.config,
  metrics: state.metrics,
  env: state.env,
  timespan: state.timespan
});

const mapDispatchToProps = {
  getMetrics
};

class App extends Component {
  render() {
    if (this.props.metrics) {
      return (
        <div className="App" style={{ paddingTop: "20px" }}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/features" component={FeaturesDash} />
              <Route exact path="/notifications" component={Notifications} />
              <Route
                exact
                path="/metrics"
                render={props => {
                  this.props.getMetrics(this.props.env, this.props.timespan);
                  return (
                    <MetricsDash
                      config={this.props.config}
                      metrics={this.props.metrics}
                    />
                  );
                }}
              />
              <Route exact path="/metrics/:platform" component={PlatformPage} />
              <Route
                exact
                path="/metrics/:platform/:service"
                component={ServicePage}
              />
            </Switch>
          </BrowserRouter>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
