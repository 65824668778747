import { SET_ENV } from './types';
import { getMetrics } from './index';

export const setEnv = (env, timeUnit) => async dispatch => {
    if(env !== 'prod' && env !== 'dev') {
        env = 'dev';
    }

    dispatch(getMetrics(env, timeUnit));
    dispatch({
        type: SET_ENV,
        data: env
    })
}