import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setEnv } from "../../actions/env";
import { setTimeSpan } from "../../actions/timeSpan";
import { setGrouping } from "../../actions/grouping";
import { Breadcrumb, Switch, Select } from "antd";
import "metrics-graphics/dist/metricsgraphics.css";

const Option = Select.Option;

const times = [
  {
    text: "Past Hour",
    unit: "hour"
  },
  {
    text: "Past Day",
    unit: "day"
  },
  {
    text: "Past Week",
    unit: "week"
  },
  {
    text: "Past Month",
    unit: "month"
  }
];

const groupings = [
  {
    text: "Hours",
    unit: "hour"
  },
  {
    text: "10 minutes",
    unit: "10minutes"
  },
  {
    text: "1 minute",
    unit: "minute"
  }
];

const mapStateToProps = state => ({
  env: state.env,
  timespan: state.timespan,
  grouping: state.grouping
});

const mapDispatchToProps = {
  setEnv,
  setTimeSpan,
  setGrouping
};

class Header extends Component {
  render() {
    const setEnv = checked => {
      if (this.props) {
        if (checked) {
          this.props.setEnv("prod", this.props.timespan);
        } else {
          this.props.setEnv("dev", this.props.timespan);
        }
      }
    };

    const setTimeSpan = unit => {
      if (this.props) {
        this.props.setTimeSpan(this.props.env, unit);
      }
    };

    const setGrouping = unit => {
      if (this.props) {
        this.props.setGrouping(unit);
      }
    };

    const timeOptions = times.map(item => (
      <Option value={item.unit} key={item.text}>
        {item.text}
      </Option>
    ));

    const groupingOptions = groupings.map(item => (
      <Option value={item.unit} key={item.text}>
        {item.text}
      </Option>
    ));

    return (
      <div>
        <div style={{ position: "inline-block" }}>
          <h1 style={{ float: "left" }}>{this.props.title}</h1>
          {!this.props.hideMetricsHeaderContent && (
            <h3 style={{ float: "right", marginTop: 10 }}>No Alerts</h3>
          )}
        </div>
        <div style={{ clear: "both" }}>
          <div style={{ float: "left" }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Dashboard</Link>
              </Breadcrumb.Item>
              {this.props.children}
            </Breadcrumb>
          </div>
          {!this.props.hideMetricsHeaderContent && (
            <>
              <div style={{ float: "right" }}>
                <Switch
                  style={{ margin: "16px 0" }}
                  onChange={setEnv}
                  checkedChildren="Prod"
                  unCheckedChildren="Dev"
                  checked={this.props.env === "prod"}
                />
              </div>
              <div style={{ clear: "both" }}>
                <div style={{ float: "right" }}>
                  <Select
                    value={this.props.timespan}
                    style={{ width: 120, marginBottom: 20, marginRight: 10 }}
                    onChange={setTimeSpan}
                  >
                    {timeOptions}
                  </Select>
                  <Select
                    value={this.props.grouping}
                    style={{ width: 120, marginBottom: 20 }}
                    onChange={setGrouping}
                  >
                    {groupingOptions}
                  </Select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
