import { db } from "../config/firebase";

export const actionTypes = {
  CREATE_FEATURE: "CREATE_FEATURE",
  GET_FEATURE: "GET_FEATURE",
  GET_FEATURES: "GET_FEATURES",
  UPDATE_FEATURE: "UPDATE_FEATURE",
  DELETE_FEATURE: "DELETE_FEATURE"
};

export const createFeature = newFeature => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (getState().features[newFeature.id]) {
      reject(Error("Feature with that ID already exists"));
    }

    db.collection("features")
      .doc(newFeature.id)
      .set(newFeature)
      .then(() => {
        resolve({ message: "Feature successfully saved", newFeature });
        dispatch({
          type: actionTypes.CREATE_FEATURE,
          data: newFeature
        });
      })
      .catch(error => reject(error));
  });

export const getFeature = id => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("features")
      .doc(id)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
          dispatch({
            type: actionTypes.GET_FEATURE,
            data: doc.data()
          });
        } else {
          reject(Error("Feature with that ID does not exist"));
        }
      })
      .catch(error => reject(error));
  });

export const getFeatures = () => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("features")
      .get()
      .then(snapshot => {
        const data = [];
        snapshot.forEach(doc => {
          data.push(doc.data());
        });
        resolve(data);
        dispatch({
          type: actionTypes.GET_FEATURES,
          data
        });
      })
      .catch(error => reject(error));
  });

export const updateFeature = feature => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("features")
      .doc(feature.id)
      .update(feature)
      .then(() => {
        resolve(feature);
        dispatch({
          type: actionTypes.UPDATE_FEATURE,
          data: feature
        });
      })
      .catch(error => reject(error));
  });

export const deleteFeature = feature => dispatch =>
  new Promise((resolve, reject) => {
    db.collection("features")
      .doc(feature.id)
      .delete()
      .then(() => {
        resolve({ message: "Feature deleted" });
        dispatch({
          type: actionTypes.DELETE_FEATURE,
          data: feature
        });
      })
      .catch(error => reject(error));
  });

export const makeNewFeature = () => {
  return {
    new: true,
    id: `NEW_FEATURE`,
    title: `New Feature`,
    description: {
      general: `This is the general description of the new feature`,
      on: `This is what happens when the new feature is on`,
      off: `This is what happens when the new feature is off`
    },
    environments: {
      dev: {
        percent: 0
      },
      prod: {
        percent: 0
      }
    },
    users: {}
  };
};

export const makeTestFeature = () => {
  return {
    id: `TEST_FEATURE`,
    title: `Test Feature`,
    description: {
      general: `This is a test feature`,
      on: `This is what happens when the test feature is on`,
      off: `This is what happens when the test feature is off`
    },
    environments: {
      dev: {
        percent: 75
      },
      prod: {
        percent: 50
      }
    },
    users: {
      testUserId0: {
        name: "Test User Name",
        dev: true,
        prod: true
      },
      testUserId1: {
        name: "Test User Name",
        dev: true,
        prod: true
      },
      testUserId2: {
        name: "Test User Name",
        dev: true,
        prod: true
      },
      testUserId3: {
        name: "Test User Name",
        dev: true,
        prod: false
      },
      testUserId4: {
        name: "Test User Name",
        dev: true,
        prod: false
      },
      testUserId5: {
        name: "Test User Name",
        dev: true,
        prod: false
      }
    }
  };
};
