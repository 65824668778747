import { actionTypes } from "../actions/notifications";

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NOTIFICATION:
      const newNotification = {};
      newNotification[action.data.id] = action.data;
      return { ...state, ...newNotification };
    case actionTypes.GET_NOTIFICATION:
      const fetchedNotification = {};
      fetchedNotification[action.data.id] = action.data;
      return { ...state, ...fetchedNotification };
    case actionTypes.GET_NOTIFICATIONS:
      const notifications = {};
      action.data.forEach(notification => {
        notifications[notification.id] = notification;
      });
      return { ...state, ...notifications };
    case actionTypes.UPDATE_NOTIFICATION:
      const updatedNotification = {};
      updatedNotification[action.data.id] = action.data;
      return { ...state, ...updatedNotification };
    case actionTypes.DELETE_NOTIFICATION:
      delete state[action.data.id];
      return { ...state };
    default:
      return state;
  }
};
