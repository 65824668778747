import React from "react";
import { Button } from "antd";

export default input => {
  const markdownTextResult = parseNotificationMarkdown(input.text);

  const messageContent = (
    <span>
      {markdownTextResult
        .filter(item => item !== "")
        .map((item, index) => {
          if (typeof item === "string") {
            if (item.includes("**")) {
              return item.split("**").map((item, j) => {
                if (j === 1) {
                  return (
                    <strong>
                      {capitalizeFirst(item.replace("**", ""), index)}
                    </strong>
                  );
                } else {
                  return <span>{capitalizeFirst(item, index)}</span>;
                }
              });
            } else {
              return <span key={index}>{capitalizeFirst(item, index)}</span>;
            }
          } else {
            return (
              <a key={index} href={`http://localhost:3000${item.link}`}>
                {capitalizeFirst(item.text, index)}
              </a>
            );
          }
        })}
      {markdownTextResult.actionText ? (
        <Button
          onClick={() => {
            window.location.href = `http://localhost:3000${input.actionLink}`;
          }}
          style={{ marginLeft: "10px" }}
        >
          {markdownTextResult.actionText}
        </Button>
      ) : (
        ""
      )}
    </span>
  );

  return messageContent;
};

const parseNotificationMarkdown = notificationText => {
  const markdownGroups = [];
  let textSegments = [notificationText];
  notificationText.replace(
    /\[([^\]]*)\]\(([^)]*)\)/g,
    (fullText, group1, group2) => {
      markdownGroups.push({
        fullText,
        text: group1,
        link: group2
      });
      const lastSegment = textSegments[textSegments.length - 1];
      const splitText = lastSegment.split(fullText);
      textSegments[textSegments.length - 1] = splitText[0];
      textSegments.push({
        fullText,
        text: group1,
        link: group2
      });
      textSegments.push(splitText[1]);
      return fullText;
    }
  );
  return textSegments;
};

const capitalizeFirst = (string, index) =>
  index === 0 ? string.charAt(0).toUpperCase() + string.substring(1) : string;
