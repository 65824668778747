import React from "react";
import VariableTextArea from "./VariableTextArea";

export default function NotificationTextRow({
  title,
  text,
  setText,
  placeholder,
  variables,
  addVariable
}) {
  return (
    <div>
      <div style={{ float: "left", width: "100%", fontSize: "18px" }}>
        {title}
      </div>
      <div style={{ float: "left", width: "100%", fontSize: "14px" }}>
        Available variables to use in text:
        {variables.map((variable, index) => (
          <span style={{ color: "#2F7EFF" }} key={index}>
            {` ${variable.name}`}
            {index !== variables.length - 1 ? "," : ""}
          </span>
        ))}
        <span
          style={{ color: "#2F7EFF", cursor: "pointer", paddingLeft: "10px" }}
          onClick={addVariable}
        >
          Add New
        </span>
      </div>
      <VariableTextArea
        setText={setText}
        placeholder={placeholder}
        text={text}
        variables={variables}
      />
    </div>
  );
}
