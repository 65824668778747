import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../Common/Header";
import { Breadcrumb, Button, Layout } from "antd";
import {
  createFeature,
  getFeatures,
  updateFeature,
  deleteFeature,
  makeNewFeature
} from "../../actions/features";
import FeatureCard from "./FeatureCard";
import "metrics-graphics/dist/metricsgraphics.css";

const { Content } = Layout;

const mapStateToProps = state => ({
  features: state.features
});

const mapDispatchToProps = {
  createFeature,
  getFeatures,
  updateFeature,
  deleteFeature
};

function Dashboard({
  features,
  createFeature,
  getFeatures,
  updateFeature,
  deleteFeature
}) {
  const [localFeatures, setLocalFeatures] = useState([]);

  useEffect(() => {
    getFeatures();
  }, []);

  useEffect(() => {
    setLocalFeatures(Object.assign([], Object.values(features)));
  }, [features]);

  const addNewFeature = () => {
    localFeatures.push(makeNewFeature());
    setLocalFeatures(Object.assign([], localFeatures));
  };

  return (
    <Content style={{ padding: "0 3%" }}>
      <Header
        title={"Aloa Platform Features Dashboard"}
        hideMetricsHeaderContent
      >
        <Breadcrumb.Item>
          <Link to="/features">Features</Link>
        </Breadcrumb.Item>
      </Header>
      <div
        style={{
          clear: "both",
          float: "left",
          width: "100%",
          marginTop: "20px"
        }}
      >
        <h4
          style={{
            float: "left",
            fontSize: "20px"
          }}
        >
          Features
        </h4>
        <Button onClick={addNewFeature} style={{ float: "right" }}>
          Add New
        </Button>
      </div>
      <div style={{ clear: "both" }}>
        {localFeatures.map(item => {
          return (
            <FeatureCard
              key={item.id}
              createFeature={createFeature}
              updateFeature={updateFeature}
              deleteFeature={deleteFeature}
              feature={item}
            />
          );
        })}
      </div>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
