import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Layout, Breadcrumb } from "antd";
import "metrics-graphics/dist/metricsgraphics.css";
import Header from "../Common/Header";

const { Content } = Layout;

const mapStateToProps = state => ({
  metrics: state.metrics,
  config: state.config
});

const mapDispatchToProps = {};

class PlatformPage extends Component {
  render() {
    var platform = "";
    var services = {};

    if (
      this.props.config.platforms &&
      this.props.match.params &&
      this.props.metrics
    ) {
      platform = this.props.config.platforms[this.props.match.params.platform];
      services = linkServiceAndMetrics(
        platform,
        this.props.config,
        this.props.metrics
      );
    }

    const sortedServiceList = Object.keys(services).sort((a, b) => {
      return services[b].total - services[a].total;
    });

    return (
      <Content style={{ padding: "0 3%" }}>
        <Header title={`${platform.name} Metrics Dashboard`}>
          <Breadcrumb.Item>
            <Link to="/metrics">Metrics</Link>
          </Breadcrumb.Item>
          {platform && <Breadcrumb.Item>{platform.name}</Breadcrumb.Item>}
        </Header>
        <h4 style={{ float: "left", marginTop: 20 }}>Services</h4>
        <div style={{ clear: "both" }}>
          {this.props.config &&
            this.props.config.services &&
            sortedServiceList.map(item => {
              return (
                <ServiceCard platform={platform} service={services[item]} />
              );
            })}
        </div>
      </Content>
    );
  }
}

export class ServiceCard extends Component {
  render() {
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Link
          to={`/metrics/${this.props.platform.id}/${this.props.service.id}`}
          style={{ textDecoration: "none" }}
        >
          <Card>
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  fontFamily: "Avenir Next",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#000000",
                  textDecoration: "none",
                  float: "left"
                }}
              >
                {this.props.service.name}
              </div>
              <div style={{ float: "right" }}>
                <div>{this.props.service.total} Total Events</div>
                <div>{this.props.service.successes} Successes</div>
                <div>{this.props.service.errors} Errors</div>
              </div>
              <div style={{ clear: "left", float: "left" }}>
                {this.props.service.count} Metrics
              </div>
              <div style={{ clear: "right" }}> </div>
            </div>
          </Card>
        </Link>
      </div>
    );
  }
}

const linkServiceAndMetrics = (platform, config, metrics) => {
  const services = {};

  Object.keys(config.types).forEach(id => {
    const metric = config.types[id];
    if (metric.platform === platform.id) {
      services[metric.service] = config.services[metric.service];
    }
  });

  Object.keys(services).forEach(key => {
    services[key].errors = 0;
    services[key].successes = 0;
    services[key].total = 0;
    services[key].count = 0;
    services[key].metrics = [];
  });

  if (metrics) {
    Object.keys(metrics).forEach(key => {
      const metric = metrics[key];
      if (services[metric.service] && metric.platform === platform.id) {
        services[metric.service].metrics.push(metric);
        services[metric.service].count++;

        metric.events.forEach(event => {
          services[metric.service].total++;
          if (event.status === "success") {
            services[metric.service].successes++;
          } else if (event.status === "failure") {
            services[metric.service].errors++;
          }
        });
      }
    });
  }

  return services;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformPage);
