import { actionTypes } from "../actions/features";

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_FEATURE:
      const newFeature = {};
      newFeature[action.data.id] = action.data;
      return { ...state, ...newFeature };
    case actionTypes.GET_FEATURE:
      const fetchedFeature = {};
      fetchedFeature[action.data.id] = action.data;
      return { ...state, ...fetchedFeature };
    case actionTypes.GET_FEATURES:
      const features = {};
      action.data.forEach(feature => {
        features[feature.id] = feature;
      });
      return { ...state, ...features };
    case actionTypes.UPDATE_FEATURE:
      const updatedFeature = {};
      updatedFeature[action.data.id] = action.data;
      return { ...state, ...updatedFeature };
    case actionTypes.DELETE_FEATURE:
      delete state[action.data.id];
      return { ...state };
    default:
      return state;
  }
};
