import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../Common/Header";
import { Layout, Card, Breadcrumb } from "antd";
import Graph from "../Graph";

const { Content } = Layout;

const mapStateToProps = state => ({
  metrics: state.metrics,
  services: state.config.services,
  platforms: state.config.platforms,
  grouping: state.grouping
});

const mapDispatchToProps = {};

class ServicePage extends Component {
  render() {
    if (this.props) {
      console.log(this.props.metrics);
      console.log(this.props.match.params);
    }

    if (this.props.platforms && this.props.services) {
      const platform = this.props.platforms[this.props.match.params.platform];
      const service = this.props.services[this.props.match.params.service];

      const metrics = Object.keys(this.props.metrics).filter(id => {
        return this.props.metrics[id].service === service.id;
      });

      const sortedMetrics = metrics.sort((a, b) => {
        return (
          this.props.metrics[b].events.length -
          this.props.metrics[a].events.length
        );
      });

      return (
        <div>
          <Content style={{ padding: "0 3%" }}>
            <Header
              title={
                this.props &&
                this.props.platforms &&
                this.props.services &&
                `${platform.name} - ${service.name} Metrics Dashboard`
              }
            >
              <Breadcrumb.Item>
                <Link to="/metrics">Metrics</Link>
              </Breadcrumb.Item>
              {platform && (
                <Breadcrumb.Item>
                  <Link to={`/metrics/${platform.id}`}>{platform.name}</Link>
                </Breadcrumb.Item>
              )}
              {service && <Breadcrumb.Item>{service.name}</Breadcrumb.Item>}
            </Header>
            <h4 style={{ float: "left", marginTop: 20 }}>Metrics</h4>
            <div
              style={{
                clear: "both",
                paddingBottom: 10,
                display: "flex",
                flexWrap: "wrap"
              }}
            >
              {sortedMetrics.map((item, index) => {
                const metric = this.props.metrics[item];
                const title = `${metric.name}`;
                if (
                  metric.platform ===
                  this.props.platforms[this.props.match.params.platform].id
                ) {
                  return (
                    <Card
                      key={index}
                      title={title}
                      bordered={false}
                      style={{
                        minWidth: "45%",
                        margin: 10,
                        textAlign: "center"
                      }}
                    >
                      <Graph
                        title={item}
                        metric={this.props.metrics[item]}
                        width={500}
                        grouping={this.props.grouping}
                      />
                    </Card>
                  );
                } else {
                  return <div />;
                }
              })}
            </div>
          </Content>
        </div>
      );
    } else {
      return (
        <div>
          <Content style={{ padding: "0 10px" }}>
            <p>Loading</p>
          </Content>
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePage);
